import { AfterViewInit, OnDestroy } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { IndexCoreMyideaApiService } from 'src/api/indexcore-myidea-api/indexcore-myidea-api.service'
import { LauncherInfo } from 'src/api/launcher-api/launcher-api.interface'
import { LaunchersService } from 'src/api/launcher-api/launcher-api.service'
import { Wbs } from 'src/api/petabo-api/petabo-api.interface'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { NotificationItem, NotificationFile } from 'src/app/components/nav/nav-menu.interface'
import { NavService } from 'src/app/components/nav/nav.service'
import { ActionTransactionService, ActionTransactionSettings } from 'src/app/directive/action-transaction.services'
@Component({
  selector: 'app-myidea-top',
  templateUrl: './myidea-top.component.html',
  styleUrls: ['./myidea-top.component.scss']
})
export class MyideaTopComponent implements OnInit, AfterViewInit, OnDestroy {
  launchers: LauncherInfo[]
  personalNotificationList: NotificationItem[] = []
  overAllNotificationList: NotificationFile[] = []
  petaboNotificationList: Wbs[] = []
  subscription: Subscription
  public viewCd = 'myidea-top'
  constructor(
    private authService: AuthService,
    private launchersService: LaunchersService,
    private navService: NavService,
    private router: Router,
    private indexCoreMyideaApiService: IndexCoreMyideaApiService,
    private actionTransactionService: ActionTransactionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getLauncherInfo()
    const s = this
    s.personalNotificationList = s.navService.getPersonalAlertAction
    s.subscription = s.navService.personalNotificationListInfoSource$.subscribe((list) => {
      s.personalNotificationList = list
    })
    s.overAllNotificationList = s.navService.getOverAllAlertAction
    s.subscription = s.navService.overAllNotificationListInfoSource$.subscribe((list) => {
      s.overAllNotificationList = list
    })
    s.navService.updatePetaboNotificationList()
    s.petaboNotificationList = s.navService.getPetaboNotificationAction
    s.subscription = s.navService.petaboNotificationListInfoSource$.subscribe((list) => {
      // s.petaboNotificationList = list
      const now = new Date().getTime()
      let filteredList = list.filter((item) => {
        const start = item.profiles.periodFrom ? new Date(item.profiles.periodFrom).getTime() : Number.NEGATIVE_INFINITY
        const end = item.profiles.periodTo ? new Date(item.profiles.periodTo).getTime() : Number.POSITIVE_INFINITY
        return start <= now && now <= end
      })

      // 最新のものを上に表示する createdAtでソート
      // createdAt: '2024-12-12T16:39:32.9062696+09:00'
      // 最新を上に表示するため、降順ソート
      filteredList = filteredList.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })

      s.petaboNotificationList = JSON.parse(JSON.stringify(filteredList))
    })

    //アクショントランザクション設定取得・保持
    let ret = await this.indexCoreMyideaApiService.getFrontendSettings('actions')
    if (ret) {
      //ライブラリ版の型に合わせておく
      let atSettings: ActionTransactionSettings = {
        enable: ret.data?.['myidea-basicSettings']?.enable,
        apiInfo: '', //Myideaでは使用しない
        data: ret.data
      }
      //アクショントランザクション出力設定情報保持=>ActionTransaction Directiveが使用
      this.actionTransactionService.setSettings(atSettings)
    }
  }

  ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  // ランチャー(製品リンク)情報取得
  async getLauncherInfo(): Promise<void> {
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) {
      return
    }
    const ret = await this.launchersService.GetLauncherInfo(customerCd)
    if (ret?.resultCode !== 0) {
      return
    }
    this.launchers = ret.data
  }

  getLauncherLogo(launcherInfo: LauncherInfo): string {
    if (launcherInfo.serviceCd === 'core.frimo') {
      return '/assets/images/common/frimo_logo.png'
    }
    if (launcherInfo.serviceCd === 'pic.frimo') {
      return '/assets/images/common/web-pack_logo.png'
    }
    if (launcherInfo.serviceCd === 'core.p-garage') {
      return '/assets/images/common/garage_logo.png'
    }
    if (launcherInfo.serviceCd === 'healthCheckup') {
      return '/assets/images/common/healthCheckup_logo.png'
    }
    if (launcherInfo.serviceCd === 'micom_s') {
      return '/assets/images/common/logo_micomel_shimamura_y.png'
    }
    return ''
  }

  serviceLink(launcherInfo: LauncherInfo): void {
    if (launcherInfo.isRelease && launcherInfo.isUser) {
      const token = this.authService.AccountInfo.token
      let tmpToken = null
      let u1 = null
      let u2 = null
      let url = launcherInfo.serviceUrl
      let i = url.indexOf('#')
      if (i !== -1) {
        u1 = url.substring(0, i)
        u2 = url.substring(i)
        tmpToken = `${u1}?token=${token}${u2}`
      }
      if (tmpToken == null) {
        tmpToken = `${url}?token=${token}`
      }
      window.location.href = tmpToken
    }
  }

  notificationLink(path: string): void {
    if (path?.startsWith('http')) {
      window.open(path, '_blank')
    } else {
      this.router.navigate([path])
    }
  }
}
